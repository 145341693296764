






















































@import '@design';
@import '@styleMixins';

.member-img {
  width: 100%;
  max-width: 250px;
}

.member-name {
  @extend .hello-bloomie--font;

  @include font-size(24px, 0.7, 0.75, 0.85);

  line-height: 1;
  text-transform: uppercase;
}

.member-title {
  @extend .rubik--font;

  @include font-size(16px, 0.7, 0.75, 0.85);
}

.member-bio {
  width: 100%;
  max-width: 500px;
  margin-right: auto;
  margin-left: auto;
}
